import { connect } from "react-redux";
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  getArticleById,
  getDrawerData,
  getNewspaperData,
  getPageData,
  getPaginatedPageData,
} from "../services/api";
import { updateEntries } from "../actions/entries";
import { updateArticleView, updateArticle } from "../actions/articleView";
import { getPlaylistByName } from "../drawer-items";
import "../../scss/nyatc/nyatc.newspaper.content.scss";
import Ad from "./ad";
import SongsOfTheWeek from "../news/sotw";
import AlbumOfTheWeek from "../news/aotw";
import Daily30 from "../news/daily30";
import Excerpt from "./excerpt";
import EventBusPublisher from "./event-bus-publisher";
import NewspaperColumn from "./column";
import SplashScreen from "../splash-screen";
import Billboard from "../news/billboard";
import useDeviceType from "../util/useDeviceType";
import { scale } from "../scale";

const initPlaylistData = async () => {
  const drawerData = await getDrawerData();
  updateEntries(drawerData);
};

export function NewspaperPageContent(props) {
  const {
    page,
    pagesByTitle,
    ads,
    playlistSOTW,
    freeAlbum,
    playlistDaily30,
    router,
    articlesByColumn,
  } = props;
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [articleNewOnNYA, setArticleNewOnNYA] = useState({});
  const renderAds = page === 3;
  const isInFrontPage = page === 3;
  const showBillboard = page === 6;
  const articleNewOnNYAId = "Inside-NYA-New-This-Week";
  const [contrarianPage, setContrarianPage] = useState({});
  const [newsPaperData, setNewsPaperData] = useState([]);
  const newsPaperContentRef = useRef(null);
  const [contentVisible, setContentVisible] = useState(false);
  const [drawerLoaded, setDrawerLoaded] = useState(false);

  // Resize effect
  useEffect(() => {
    window.addEventListener("resize", resizeContent);
    resizeContent();

    return () => {
      window.removeEventListener("resize", resizeContent);
    };
  }, [resizeContent, pageData]);

  // Initial data loading effect
  useEffect(() => {
    getNewspaperData().then((newsPaperData) => {
      updateEntries(newsPaperData);
      setNewsPaperData(newsPaperData);
      if (newsPaperData?.contrarianPage) {
        const contrarianPage = newsPaperData.contrarianPage;
        const contrarianPageData = handleContrarianPage(contrarianPage);
        setContrarianPage(contrarianPageData);
      }
    });
    let timer;
    if (isInFrontPage) {
      if (!drawerLoaded) {
        timer = setTimeout(() => {
          initPlaylistData();
        }, 5000);
      }
      fetchArticleNewOnNYA().then((r) => console.log(r));
      return () => timer && clearTimeout(timer);
    }
  }, []);

  // Page change effect
  useEffect(() => {
    if (newsPaperData?.contrarianPage) {
      const contrarianPage = newsPaperData.contrarianPage;
      const contrarianPageData = handleContrarianPage(contrarianPage);
      setContrarianPage(contrarianPageData);
    }
  }, [page]);

  // Page & pageByTitle effect
  useEffect(() => {
    const pageId = `page-${page}`;
    const _pageData = pagesByTitle[pageId];
    setPageData(_pageData);
  }, [page, pagesByTitle]);

  // Page data loading effect
  useEffect(() => {
    props.onLoadingStart();
    setLoading(true);
    getPaginatedPageData(page).then(({ data, ...res }) => {
      updateEntries({
        articles: data,
        total: res.total,
        totalItems: res.totalItems,
      });
      setLoading(false);
    });
  }, [page]);

  const resizeContent = useCallback(() => {
    let element = newsPaperContentRef.current;
    const s = scale();

    if (element) {
      element.style.zoom = s;
    }

    // Show content after resize
    setContentVisible(true);
  }, []);

  const _getArticleData = async (articleId) => {
    const url = `article?id=${articleId}`;
    EventBusPublisher.changePage(`1/${url}`);
    const response = await getArticleById(articleId);
    updateArticle(response);
    router.push(`/times-contrarian/${page}/${url}`);
  };

  const _linkToArticle = (articleId) => {
    updateArticleView(true);
    _getArticleData(articleId).then((r) => console.log(r));
  };

  const fetchArticleNewOnNYA = async () => {
    const { article } = await getArticleById(articleNewOnNYAId);
    setArticleNewOnNYA(article);
  };

  const getColumnsByLayout = (columns, layout) => {
    return columns[layout].map((column, i) => {
      const articles = articlesByColumn[column] || [];
      return (
        <NewspaperColumn
          totalArticlesInColumn={articles.length}
          column={column}
          key={layout + "_" + column}
          layout={layout}
          articles={articles}
          page={page}
          router={router}
          contrarianPage={contrarianPage}
        />
      );
    });
  };

  const _renderColDivs = (layout = "column-3") => {
    const columns = {
      "column-3": ["left", "center", "right"],
      "column-3-full": ["left", "center", "right"],
      "column-3-equal": ["left", "center", "right", "fourth"],
      "column-4": ["left", "center", "right", "fourth"],
    };
    if (!columns[layout]) {
      console.warn(`Unhandled layout: ${layout}`);
      return null;
    }

    return (
      <div className={`columns ${layout}`}>
        {getColumnsByLayout(columns, layout)}
      </div>
    );
  };

  const _renderAdsColumn = () => {
    return (
      <div className="column ads">
        {playlistSOTW && (
          <div
            onClick={() => {
              EventBusPublisher.parentRedirect(`/playlist?id=${playlistSOTW}`);
            }}
          >
            <SongsOfTheWeek />
          </div>
        )}
        {freeAlbum && (
          <div
            onClick={() => {
              EventBusPublisher.parentRedirect(`/album?id=${freeAlbum.id}`);
            }}
          >
            <AlbumOfTheWeek />
          </div>
        )}
        {playlistDaily30 && (
          <div
            onClick={() => {
              if (playlistDaily30?.id) {
                EventBusPublisher.parentRedirect(
                  `/playlist?id=${playlistDaily30.id}`
                );
              }
            }}
          >
            <Daily30 waiting={!playlistDaily30?.id ? "waiting" : ""} />
          </div>
        )}
        {isInFrontPage && (
          <Excerpt
            key={`excerpt-${page}-${articleNewOnNYAId}`}
            data={articleNewOnNYA}
            idx={articleNewOnNYAId}
            page={page}
            layout={"column-3"}
            pageStyle={"front-page"}
            link={_linkToArticle}
          />
        )}
        {ads.map((item, index) => (
          <Ad
            link={item.link}
            image={`${item.image}?fm=jpg&fl=progressive&fit=thumb&w=332`}
            key={index}
          />
        ))}
      </div>
    );
  };

  const _renderBillboardColumn = () => {
    return (
      <div className="column ads">
        <Billboard key="top40" which="top40" shrink />
      </div>
    );
  };

  const handleContrarianPage = (contrarianPage) => {
    for (let key in contrarianPage) {
      if (contrarianPage[key].order === page) {
        return contrarianPage[key];
      }
    }
    return null;
  };

  if (loading)
    return (
      <SplashScreen
        style={{ height: "100vh", width: "100%" }}
        loadState={100}
      />
    );

  return (
    <div ref={newsPaperContentRef} className="content" id="newspaper_content">
      {_renderColDivs(pageData?.layout)}
      {renderAds ? _renderAdsColumn() : null}
      {showBillboard ? _renderBillboardColumn() : null}
    </div>
  );
}

NewspaperPageContent.contextTypes = {
  page: PropTypes.number,
  pagesData: PropTypes.object,
  pagesByTitle: PropTypes.object,
  isArticleView: PropTypes.bool,
};

const mapToStateProps = (state) => {
  let pagesData = state?.contrarianPage?.all || [];
  pagesData = pagesData.sort((a, b) => a.order - b.order);

  const parsedAds = state.contrarianAd.all
    .filter((item) => item.link !== undefined)
    .sort(() => 0.5 - Math.random());

  const freeAlbums = state.commonValues.freeAlbums || {};
  const freeAlbum =
    Object.keys(freeAlbums).length > 0
      ? freeAlbums[Object.keys(freeAlbums)[0]]
      : null;
  const playlistDaily30 = getPlaylistByName("NYA Daily 30");

  return {
    data: state?.newsPage?.page || {},
    pagesData: pagesData,
    articlesByColumn: state?.newsArticle.byColumn || {},
    articlesTotalItems: state?.newsArticle.totalItems || 0,
    articlesTotal: state?.newsArticle.total || 0,
    pagesByTitle: state?.contrarianPage?.byTitle || {},
    isArticleView: state.articleView.show,
    ads: parsedAds,
    playlistSOTW: state.commonValues.playlistSOTW,
    freeAlbum,
    playlistDaily30,
  };
};

export default connect(mapToStateProps, {})(NewspaperPageContent);
