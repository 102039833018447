import React, {Component}           from 'react'
import ScrollBars                   from 'react-scrollbar'
import cn                           from 'classnames'
import _                            from 'lodash'
import PropTypes                    from 'prop-types'
import Input                        from './components/input'
import {
        searchByQuery,
        searchByMyLocation
    }                               from './services/record-store'
import recordImg from '../images/search-record-store-day/record.webp'
import {resetMetadata} from './actions/metas'

const cache = Object.create(null)
const resetResults = {stores: [], query: ""}
const myLocation = 'My location'

let searchId = 0
let lastSearch = ''

function query({query}){
    if (cache[query]) return cache[query]

    const stores = _.lowerCase(query) == _.lowerCase(myLocation) ? searchByMyLocation() : searchByQuery(query)
    const storesFetch = [stores]
    return Promise.all(storesFetch).then(storesResult => {
        console.log({storesResult})
        const [stores] = storesResult
        cache[query] = {stores}

        // if (console.table){
        //     console.table(stores)
        // } else {
        //     console.log(stores)
        // }

        return {stores}
    })
    .catch(err => {
        console.error('Error fetching stores', err)
    })
}

function queryCached(q){
    return new Promise(resolve=>resolve(query(q)))
}

function getStoreLink(store, label, def) {
    const {website_address, facebook} = store

    if (_.isEmpty(website_address) && _.isEmpty(facebook)) return def || label

    if (!_.isEmpty(website_address)) {
        return (<a href={website_address} target="_blank">{label}</a>)
    }

    return (<a href={`https://www.facebook.com/${facebook}`} target="_blank">{label}</a>)
}

function desc(item, idx){

    return (
        <tr className="result" key={idx}>
            <th className="name">{getStoreLink(item, item.name)}</th>
            <td className="distance">{item.distance_in_miles ? `${item.distance_in_miles} mi` : ''}</td>
            <td className="city">{_.startCase(_.lowerCase(item.city))}</td>
            <td className="website">{getStoreLink(item, 'Website', '\u2014')}</td>
        </tr>
    )
}

class NoQuery extends Component {
    render(){
        return (
            <div className="rsd__no-query">
                <div className="rsd__no-query__thumb">
                    <img src={recordImg} width="407" height="407" alt="" loading="lazy"/>
                </div>
                <div className="rsd__no-query__copy">
                    <p>We have made it easy for you to deal with real people face to face in a record store. It’s fun to browse vinyl selections in their bins.
                        I have loved doing this my whole life. These folks are there for you - to help you find the music you love.
                        Enjoy the trip and i hope you find what you are looking for!
                    <br /><br /><span>NY</span>
                    <br /><span>NYA</span></p>
                </div>
            </div>
        )
    }
}

class NoResults extends Component {
    render(){
        return (
            <div className="rsd__no-results">
                <div className="rsd__no-results__for"><span>Search results for "{this.props.query}"</span></div>
                <p className="rsd__no-results__label">Nothing found!</p>
            </div>
        )
    }
}

class SearchResults extends Component {
    render(){
        let {results} = this.props
        let {stores, query} = results

        if (!query) return <NoQuery />

        if (!_.size(stores)) return <NoResults query={query} />

        return (
            <div className="rsd__results">
                <div className="rsd__results__for"><span>Search results for "{query}"</span></div>
                <div className="rsd__results__stores">
                    <ScrollBars>
                        <table>
                            <tbody>
                                {
                                    stores.map((item, idx)=>(desc(item, idx)))
                                }
                            </tbody>
                        </table>
                    </ScrollBars>
              </div>
            </div>
        )
    }
}

export default class SearchContainer extends Component {
    constructor(props, ctx) {
        super(props, ctx)

        let {q} = props.location.query

        q = q || lastSearch

        this.state = {results: resetResults, q, loading: false}

        this.onChange  = _.debounce(this.onChange.bind(this), 200)
        this.onClick   = this.onClick.bind(this)
        this.onClose   = this.onClose.bind(this)
    }

    componentWillMount(){
        const {q} = this.state
        if (q) this.search(q)

        resetMetadata()
    }

    onChange(search){
        const q = search.trim()
        this.setState({q})
        this.search(q)
    }

    onClose(){
        this.context.router.goBack()
    }

    onClick(){
        // update ./input/states
        this.refs.search.state.value = this.refs.search.value = myLocation

        this.search(myLocation)
    }

    updateUrl(search){
        let {router}   = this.context
        let {pathname} = this.props.location

        let query = {q: search}

        let location = router.createLocation({pathname, query})
        delete location.search

        router.replace(location)
    }

    search(search){
        this.updateUrl(search)

        if (search === '') {
            this.setState({results: resetResults})
            return
        }

        this.setState({loading: true})

        lastSearch = search

        searchId +=1
        let thisSearch = searchId

        queryCached({query:search}).then(results => {

            if (searchId !== thisSearch) return

            this.setState({results: Object.assign({}, results, {query: search}), loading: false})
        })
    }

    render(){
        const {results, q} = this.state
        const cx = cn('rsd', {'rsd--loading': !!this.state.loading})

        return (
            <div className={cx}>
                <div className="rsd__close" onClick={this.onClose}/>
                <div className="rsd__box">
                    <span className="tape tape--1"></span>
                    <span className="tape tape--2"></span>
                    <span className="tape tape--3"></span>
                    <span className="tape tape--4"></span>
                    <div className="rsd__box__inner">
                        <div className="rsd__box__inner__close" onClick={this.onClose}/>
                        <div className="rsd__box__inner__heading" />
                        <div className="rsd__box__inner__form">
                            <div className="rsd__box__inner__form__location">
                                <button className="button" onClick={this.onClick}>Find my location</button>
                            </div>
                            <div className="rsd__box__inner__form__or">
                                <span>OR</span>
                            </div>
                            <div className="rsd__box__inner__form__search">
                                <Input className="searchbox"
                                        value={q}
                                        autofocus={true}
                                        focusOnClear={true}
                                        topShadow={true}
                                        placeholder="City, state, zip or store name"
                                        ref='search'
                                        onChange={this.onChange} />
                                <div className="rsd__box__inner__form__search__international">
                                    <a className="button clear small" href="https://recordstoreday.com/Stores?country=select" target="_blank">International search</a>
                                </div>
                            </div>
                        </div>
                        <div className="rsd__box__inner__results">
                            <SearchResults results={results} />
                        </div>
                        <div className="rsd__box__inner__footer">
                            <p>Celebrating the culture of the indie record store, 365 days a year, including one very special Saturday.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SearchContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
