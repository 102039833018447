import {ARTICLE_ENTRIES_APPEND, ENTRIES_LOADED, UPDATE_ARTICLES} from '../constants';

export default function update(state = {byId:{}, all:[], byColumn: {}}, action) {
    const payload = action.entries;
    switch (action.type) {
        case ENTRIES_LOADED:
            if(payload.articles) {
                let result = {byId:{}, all:[]}
                const allArticles = Object
                  .values(payload.articles)
                  .flat();

                allArticles.forEach(article => {
                    result.byId[article._id] = article;
                });

                result.all = allArticles;
                result.byColumn = payload.articles;
                result.totalItems = payload.totalItems;
                result.total = payload.total;
                return result
            }
            break;

        case UPDATE_ARTICLES:
            if(action.articles) {
                return action.articles
            }
            return state
        break;

        case ARTICLE_ENTRIES_APPEND:
            const articlesByColumn = state.byColumn;
            const newArticles = action.entries.articles;
            const newTotalItems = action.entries.totalItems;
            const newTotal = action.entries.total;
            const allArticles = state.all.concat(Object.values(newArticles).flat());
            const allArticlesById = allArticles.reduce((acc, article) => {
                acc[article._id] = article;
                return acc;
            }, {});

            for (let column in newArticles) {
                if (articlesByColumn[column]) {
                    articlesByColumn[column] = articlesByColumn[column].concat(newArticles[column]);
                } else {
                    articlesByColumn[column] = newArticles[column];
                }
            }
            return {
                ...state,
                byId: allArticlesById,
                all: allArticles,
                byColumn: articlesByColumn,
                totalItems: newTotalItems,
                total: state.total + newTotal
            }

          break;
        default:
            return state;
    }
    return state;
}
