// Please delete this function when done
import React, {Fragment, useEffect, useRef, useState} from "react";
import Excerpt from "./excerpt";
import {getArticleById} from "../services/api";
import {updateArticle, updateArticleView} from "../actions/articleView";
import EventBusPublisher from "./event-bus-publisher";
import ExcerptWrapper from "./excerpt-wrapper";

const INITIAL_NUMBER_OF_ARTICLES = 7;

function isDuplicated(id, setOfElements) {
  if (setOfElements.has(id)) {
    return true
  } else {
    setOfElements.add(id);
    return false;
  }
}

export const NewspaperColumn = (props) => {
  const {column, layout, articles, page, router, contrarianPage, totalArticlesInColumn } = props
  const articleIds = new Set();

  const _getArticleData = async (articleId) => {
    const url = `article?id=${articleId}`
    EventBusPublisher.changePage(`${page}/`+url);
    const response = await getArticleById(articleId);
    updateArticle(response);
    router.push(`/times-contrarian/${page}/${url}`);
  };


  const _linkToArticle = (articleId) => {
    updateArticleView(true)
    _getArticleData(articleId).then(data => {});
  }

  const renderHeader = () =>{
    switch (column) {
     case 'left':
      return (
       <Excerpt
        key="left-header"
        data={{ headlineText: contrarianPage.leftHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'center':
      return (
       <Excerpt
        key="center-header"
        data={{ headlineText: contrarianPage.centerHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'right':
      return (
       <Excerpt
        key="right-header"
        data={{ headlineText: contrarianPage.rightHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'fourth':
      return (
       <Excerpt
        key="fourth-header"
        data={{ headlineText: contrarianPage.fourthHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'fifth':
      return (
       <Excerpt
        key="fifth-header"
        data={{ headlineText: contrarianPage.fifthHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     default:
      console.warn(`Unhandled column: ${column}`);
      return null;
    }

  }

  return (
    <div className={`column ${column} ${layout}`}>
    {contrarianPage && renderHeader()}
    {articles.map((article, i) => {
      let hidden = isDuplicated(article._id, articleIds);
      if (hidden) {
        return null;
      }
      return <ExcerptWrapper
        key={`container-${layout}-${column}-${i}`}
        layout={layout}
        column={column}
        index = {i}
        data={ article}
        totalArticlesInColumn={totalArticlesInColumn}
        page={page}
        pageStyle={'front-page'}
        linkToArticle={_linkToArticle}
      />
      })}
    </div>
  )
}

export default NewspaperColumn;
