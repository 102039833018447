import React, {useRef, useState, useEffect} from 'react';
import {useIntersectionObserver} from "@uidotdev/usehooks";
import Excerpt from "./excerpt";
import { getPaginatedPageData } from '../services/api';
import {connect} from "react-redux";
import {appendArticles} from "../actions/entries";


export const ExcerptWrapper = (props) => {
  const {
    data,
    layout,
    column,
    index,
    page,
    linkToArticle,
    totalItems,
    total,
    totalArticlesInColumn
  } = props;
  const excerptRef = useRef(null);
  const [hasFetched, setHasFetched] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  const isTriggerArticle = (index+1) === (totalArticlesInColumn - 3);
  const nextOffset =  total;

  useEffect(() => {
    if (isTriggerArticle && entry?.isIntersecting && !hasFetched) {
      console.log("@@@ reached end of articles");
      getPaginatedPageData(page, 25, nextOffset).then(({data, ...res}) => {
        console.log(data, res);
        appendArticles({
          articles: data,
          total: res.total,
          totalItems: res.totalItems
        })
      });
      setHasFetched(true);
    }
  }, [entry?.isIntersecting]);


  return (
    <div ref={ref} id={`excerpt-wrapper-${layout}-${column}-${index}`}>
      <Excerpt
        ref={excerptRef}
        key={`excerpt-${layout}-${column}-${index}`}
        data={data}
        layout={layout}
        page={page}
        link={linkToArticle}
        pageStyle={'front-page'}
        style = {{visibility: !entry?.isIntersecting ? 'hidden' : 'visible'}}
      />
    </div>
  );
}

const mapToStateProps = (state) => {
  return {
    totalItems: state.newsArticle.totalItems,
    total: state.newsArticle.total
  }
}

export default connect(mapToStateProps, {})(ExcerptWrapper);
