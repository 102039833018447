import superagent from 'superagent'
import { BRIO_VIDEO } from '../util/contants/url_constants'

export const getBrioVideoInformation = (videoId) => {
    return new Promise((resolve) => {
        superagent.get(BRIO_VIDEO + videoId + '?logo=https://cdn.neilyoungarchives.com/artwork/shakey-pictures-logo.webp&autoplay=1')
        .end((err, res) => {
            if (err || !res.ok) {
                console.error(`Error fetching video information for videoId ${videoId}:`, err);
                resolve({ error: 'Failed to fetch video information', details: err });
                return;
            }
            try {
                const data = JSON.parse(res.text);
                if (data && data.body) {
                    resolve(data.body);
                } else {
                    resolve(null);
                }
            } catch (parseError) {
                console.error(`Error parsing response for videoId ${videoId}:`, parseError);
                resolve({ error: 'Failed to parse video information', details: parseError });
            }
        });
    });
}
