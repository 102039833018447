import React, { Component } from 'react';
import VerticalCenter from './components/vertical-center';
import messageImg from '../images/not-supported/v3/message.webp';
import topSliceImg from '../images/not-supported/v3/top-slice.webp';

export default class NotSupported extends Component {
    render(){
        return (
            <div className="not-supported-screen-v3">
              <VerticalCenter className="background">
                <img src={messageImg} loading="lazy"/>
              </VerticalCenter>
              <img src={topSliceImg} className="top-bar" loading="lazy"/>
            </div>
        )
    }
}
