import React, { Component } from "react";
import cn from "classnames";

import PlaylistManager from "../components/new-player/playlist-manager";
import _ from "lodash";
import { infoCardIsLockedByID } from "../user";
import Audio from "../components/new-player/audio";
import Mp3Audio from '../components/new-player/mp3-player/mp3-audio';

import PlayerBtn  from './player-btn';


class Track extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { hover: false };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.createLocation = this.createLocation.bind(this);
  }
  onMouseEnter(track, trackId ) {
    const {orastream=false, mp3audio=false} = track
    //mp3audio just to display the hover stay of the play btn
    if(orastream && trackId || mp3audio ){
      this.setState({ hover: trackId });
    }
  }
  onMouseLeave() {
    this.setState({ hover: false });
  }
  formatDuration(length) {
    if (!length) return "00:00";
    return length;
  }
  createLocation(route) {
    const { pathname, query } = route;
    let { router } = this.props.context;
    //set state in location
    let location = router.createLocation({
      pathname,
      query,
      state: "performance-log",
    });

    //query parameters are duplicated if this is not deleted
    delete location.search;
    router.push(location);
  }

  viewTrackOnTimeline(track) {
    if (!_.isEmpty(track) && _.has(track, "releaseDate")) {
      const { id, releaseDate } = track;
      let pathname = "/timeline-months";
      let { month, year } = releaseDate;
      let query = { currTrack: id, month, year };
      this.createLocation({ pathname, query });
    }
  }
  renderRequests(requests) {
    let userRequests = [];
    if (!_.isEmpty(requests)) {
      requests.map((request, key) => {
        if (key <= 2) {
          userRequests.push(<div key={key}>{request.userName}</div>);
        }
      });
    }
    return <div className="user-requests">{userRequests}</div>;
  }

  render() {
    let {
      track = {},
      trackNumber,
      odd,
      currentlyPlaying,
      isMp3Playing,
      userRequestItems,
      showRequests,
      trackRequests,
      requestViewed,
      _id,
      
    } = this.props;
    let { hover } = this.state;
    let {
      trackId = `track-${trackNumber}`,
      title,
      length,
      lowResolution = false,
      mp3audio,
      orastream,
      listOnly = true,
      hiddenTrack = false,
      originalRelease =[]
    } = track || {};

    let time = this.formatDuration(length);

    let scale = 35 / 66; /* for scaling down the music player inline */
    let style = { transform: `scale(${scale})` };
    //display the icons if this track is assigned to a original release
    let displayIcon = !(_.isEmpty(originalRelease))
    //hide infotrack and timeline icon if list or hiddenTrack is set to yes 
    if(listOnly || hiddenTrack)displayIcon = false

    let displayOnly =
      (!orastream && lowResolution && !mp3audio) || (!orastream && !mp3audio) || !orastream;
    let titleClass = cn("title", { currentlyPlaying });
    let trackClass = cn("track", { displayOnly, odd });
    let userRequesImage = "";
    let showUserRequest = false;
    let requestVisited = requestViewed ? " active" : " innactive";

    // Check if is available for free users
    const disabledbyId = infoCardIsLockedByID(trackId);
    if (!_.isEmpty(userRequestItems) && !_.isEmpty(trackRequests)) {
      showUserRequest = userRequestItems.reduce((prev, curr) => {
        return Math.abs(curr.minRequest - trackRequests.length) <
          Math.abs(prev.minRequest - trackRequests.length)
          ? curr
          : prev;
      });
    }
    if (
      !_.isEmpty(showUserRequest) &&
      _.has(showUserRequest, "symbol.fields.file")
    ) {
      let { url } = showUserRequest.symbol.fields.file;
      userRequesImage = url;
    }
    return (
      <tr
        className={trackClass}
        onMouseEnter={() => this.onMouseEnter(track, trackId)}
        onMouseLeave={() => this.onMouseLeave()}
        data-music-id={track && track.id ? track.id : ""}
        key={trackId}
       
      >
         {hover == trackId || currentlyPlaying || isMp3Playing ? (
          <PlayerBtn
          track={track}
          currentlyPlaying = {currentlyPlaying}
          isMp3Playing = {isMp3Playing}
          disabledbyId={disabledbyId}
          style={style}
          onTrackElementClick={this.props.onTrackElementClick}
        />):(
          <td className="track-number"><div>{trackNumber}</div></td>
        )}

        <td className={titleClass} data-music-id={trackId}>
          <div className="titleText">{title}</div>
        </td>
        <td className="low-resolution">
          {lowResolution || mp3audio? <span>320 Resolution</span> : <span />}
        </td>
        <td>
          {displayIcon ? (
            <div
              className="track-timeline-icon"
              onClick={() => this.viewTrackOnTimeline(track)}
              data-id={trackId}
            />
          ) : (
            <div className="track-timeline-icon hidden" />
          )}
        </td>
        <td>
          {displayIcon ? (
            <div className="track-infocard-icon-wrapper">
              <div
                className="track-infocard-icon"
                onClick={() =>
                  this.createLocation({
                    pathname: "/info-card",
                    query: { track: trackId },
                  })
                }
                data-id={trackId}
              ></div>
            </div>
          ) : null}
        </td>
        <td className="track-duration-wrapper" valign="center">
          <div className="track-duration">{time === "00:00" ? "" : time}</div>
        </td>
        <td className="perf-request"
          onClick={() =>
            showRequests({
              mediaId: _id,
              requestsSelected: trackRequests,
              mediaData: "setList",
            })
          }
        >
          {showUserRequest && (
            <span>
              <img
                className={`${requestVisited} ${showUserRequest.type}`}
                src={userRequesImage}
                loading='lazy'
              />
            </span>
          )}
        </td>
      </tr>
    );
  }
}

export default class TrackListing extends Component {
  constructor(props, b) {
    super(props, b);

    this.state = { currTrack: PlaylistManager.currentTrack()};

    this.audioCallback = this.audioCallback.bind(this);
    this.playlistCallback = this.playlistCallback.bind(this);
  }
  componentDidMount() {
    Audio.addCallback(this.audioCallback);
    PlaylistManager.addListener(this.playlistCallback);
  }
  componentWillUnmount() {
    Audio.removeCallback(this.audioCallback);
    PlaylistManager.removeListener(this.playlistCallback);
  }
  audioCallback({ state: playerStatus }) {
      this.setState({ playerStatus });
  }
  playlistCallback({ track: currTrack }) {
    this.setState({ currTrack });
  }

  render() {
    let {
      tracks,
      userRequestItems,
      showRequests,
      isRequestVisible,
      onTrackElementClick
    } = this.props;
    let { currTrack, playerStatus } = this.state;
    return (
      <div className="track-listing">
        <table id="track-table" className="tracks">
          <tbody>
            <tr>
              <th className="track-number">#</th>
              <th>Song</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                User
                <br />
                Request
              </th>
            </tr>
            {tracks.map((track, idx) => {
              let trackRequests = track && track.userRequests || {};
              const { _id, requestViewed } = track;
              track = track && track.asset;

              let odd = (idx + 1) % 2 === 1;
              
              let currentlyPlaying = (currTrack === track);
              let isMp3Playing = currentlyPlaying && Mp3Audio.isMp3Playing() ? true : false;

              return (
                <Track
                  dispatch={this.props.dispatch}
                  tracksMap={this.props.tracks}
                  isMp3Playing={isMp3Playing}
                  trackNumber={idx + 1}
                  onTrackElementClick={(item) => onTrackElementClick(item, playerStatus)}
                  currentlyPlaying={currentlyPlaying}
                  trackRequests={trackRequests}
                  userRequestItems={userRequestItems}
                  showRequests={showRequests}
                  track={track}
                  _id={_id}
                  requestViewed={requestViewed}
                  odd={odd}
                  key={idx}
                  isRequestVisible={isRequestVisible}
                  context={this.props.context}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

