import React, {Component} from 'react'
import _                  from 'lodash'
import Billboard          from './billboard'
import AlbumOfTheWeek     from './aotw'
import SongsOfTheWeek     from './sotw'
import Daily30            from './daily30'
import Ad                 from './ad'
import {getNewsData}      from './article-data'
import {Link}             from 'react-router'
import { getPlaylistByName } from '../drawer-items'
import {getArticleById, getPlaylist, getTopChart} from '../services/api';
import { connect }         from 'react-redux';
import Excerpt from "../newspaper-iframe/excerpt";
import {updateArticle, updateArticleView} from "../actions/articleView";

export const articleNewOnNYAId = "Inside-NYA-New-This-Week"

class AdColumn extends Component {
    constructor(props){
        super(props)
        this.state = {
            playlistSOTW: '',
            freeAlbum: '',
            ads: [],
            top40: [],
            articleNewOnNYA: {}
        }
        this.fetchTopChartsData = this.fetchTopChartsData.bind(this)
        this.fetchArticleNewOnNYA = this.fetchArticleNewOnNYA.bind(this)
        this._linkToArticle = this._linkToArticle.bind(this)
        this.INTERVAL_UPDATE_TOP_CHART = process.env.UPDATE_TOP_CHART_INTERVAL || 900000
    }

    componentDidMount() {
      const { page } = this.props
      const showBillboard = page ? +page === 5 : false;
      const isInFrontPage = page ? +page === 2 : false;
      if(showBillboard && !(this.props.showLinks)) {
        this.fetchTopChartsData()
        this.intervalId = setInterval(this.fetchTopChartsData, this.INTERVAL_UPDATE_TOP_CHART)
      }
      if (isInFrontPage) {
        this.fetchArticleNewOnNYA()
      }
    }
    componentWillUnmount(){
      if(this.intervalId) {
        clearInterval(this.intervalId)
      }
    }
    async fetchTopChartsData(){
      const {top40 = []} = await getTopChart('all') || {}
      let newState = Object.assign({}, this.state);
      newState.top40 = top40
      this.setState(newState);
    }

    async fetchArticleNewOnNYA() {
      const {article} = await getArticleById(articleNewOnNYAId);
      let newState = Object.assign({}, this.state);
      newState.articleNewOnNYA = article
      this.setState(newState);
    }

  _getArticledata(articleId){
    const { page } = this.props

    window.history.pushState({}, null, `/news/${page}/article?id=${articleId}`);

    getArticleById(articleId).then(data => {
      updateArticle(data)
    })
  }

  _linkToArticle (articleId){
    const { updateArticleView} = this.props
    updateArticleView(true)
    this._getArticledata(articleId);
  }

    render() {

      const { playlistSOTW = null, freeAlbum = null, playlistDaily30 = null } = this.props
      const { page } = this.props
      const showButtons = page ? +page === 1 : false
      const showBillboard = page ? +page === 5 : false;
      const isInFrontPage = page ? +page === 2 : false;
        return (
            <div className="column ads">
              {
                showButtons ? playlistSOTW ? (
                  <Link to={`/playlist?id=${playlistSOTW}`}><SongsOfTheWeek /></Link>
                ) : <SongsOfTheWeek /> : undefined
              }
              {
                showButtons ? freeAlbum && freeAlbum.id ? (
                  <Link to={`/album?id=${freeAlbum.id}`}><AlbumOfTheWeek /></Link>
                ) : <AlbumOfTheWeek /> : undefined
              }
              {
                showButtons ? playlistDaily30 && playlistDaily30.id ? (
                  <Link to={`/playlist?id=${playlistDaily30.id}`}><Daily30 /></Link>
                ) : <Daily30 /> : undefined
              }
              {
                isInFrontPage ? <Excerpt
                  key={`excerpt-${page}-${articleNewOnNYAId}`}
                  data={this.state.articleNewOnNYA}
                  idx={articleNewOnNYAId}
                  page={page}
                  layout={"column-3"}
                  pageStyle={"front-page"}
                  link={this._linkToArticle}
                /> : null
              }
              {
                showBillboard ? this.props.showLinks ? (
                  <Link to="/news/top-40-tracks"><div className="ad top40" /></Link>
                ) : <Billboard key="top40" which="top40" shrink topChartData={this.state.top40}/> : undefined
              }
              {
                !showBillboard && this.props.ads.map(({height, image, link}, idx) => <Ad height={height} image={`${image}?fm=jpg&fl=progressive&fit=thumb&w=332`} link={link} key={idx}/>)
              }
            </div>
        )

    }
}

const mapStateToProps = function (state) {
  let {aAds = [], bAds = []} = state.newsPage.page || {};
  aAds =  aAds.sort(()=>0.5 - Math.random())
  bAds =   bAds.sort(()=>0.5 - Math.random());
  let contrarianAds = state.contrarianAd.byContentfulId || {};
  let parsedAds = []
  const ads = aAds.concat(bAds);
  ads.forEach((adRef) => {
    if (adRef && adRef._id){
      const _ad = contrarianAds[adRef._id];
      if (_ad && _ad.link) parsedAds.push(_ad);
    }
  });

  const freeAlbums = state.commonValues.freeAlbums || {};
  const freeAlbum = Object.keys(freeAlbums).length > 0 ? freeAlbums[Object.keys(freeAlbums)[0]] : null;
  const playlistDaily30 = getPlaylistByName('NYA Daily 30')
  return {
    newsPage: state.newsPage.page,
    playlistSOTW: state.commonValues.playlistSOTW,
    ads: parsedAds,
    freeAlbum,
    playlistDaily30
  };
};

export default connect(mapStateToProps, {updateArticleView, updateArticle})(AdColumn);
