import React, {Component} from 'react'
import VerticalCenter     from './components/vertical-center'
import previewImg from '../images/preview.jpg'

export default class PreviewPage extends Component {
    render(){
        const style = {width:'100%'}
        return (
            <VerticalCenter className="preview-page">
              <img src={previewImg} style={style} loading="lazy"/>
            </VerticalCenter>
        )
    }
}
