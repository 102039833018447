import React, { Component, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import cn from "classnames";
import PropTypes from "prop-types";
import VOD from "./vod";
import Schedule from "./schedule.js";
import Splash from "./splash.js";
import Streaming from "./streaming.js";
import VideoJS from "./video";
import { getMoviesByScreenCategory, getMovieByQuery } from "./movies";
import { getMovie, getHearseTheaterData } from "../services/api";
import { getThumbnail } from "../services/vimeo";
import { updateMovie } from "../actions/movie";
import Audio from "../components/new-player/audio";
import { resetMetadata } from "../actions/metas";
import { requiredSubscription } from "../user";
import { connect } from "react-redux";
import { VIDEO_PLAYING, VIDEO_PAUSED } from "../constants";
import { updateVideoStatus } from "../actions/movie";
import { parseUrlStr } from "../util/strings";
import windowImg from "../../images/movie-night/window-min.webp";
import downArrow from "../../images/movie-night/down_arrow.webp";
import theaterPhoto from "../../images/movie-night/shakey-presents.webp"
import SplashScreen from "../splash-screen";
import MovietoneLogo from './movietoneLogo';
import { movieNightParser } from "../drawer-items";
import store from '../store';
import { cps } from "redux-saga/effects";

/*

   pre -> streaming -> post

   schedule

 */

class MovieNight extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    const movieCategories = this.props.movieNightCategory || [];
    let pathLocation = location.pathname;
    let path = parseUrlStr(pathLocation);
    let pathTitle = path[2] || null;
    let vimeoQuery = path[3] || null;
    let categoryInfo = movieCategories.filter((category) => {
      let categoryTitle = category.title || "";
      if (
        pathTitle &&
        pathTitle == categoryTitle.toLowerCase().replace(/\s/g, "")
      ) {
        return category.name;
      }
    })[0];

    let queryCategory = (categoryInfo !== undefined
      ? categoryInfo
      : movieCategories[0]
    ).name;

    const moviesByScreen = getMoviesByScreenCategory(queryCategory);
    let movies = moviesByScreen.parsedFilms || [];

    const filmsByCategory = store.getState().movieNightFilm.byCategoryName || {};
    const allMovies = filmsByCategory[queryCategory] || [];
    let film = allMovies.find(film => film.isFeatured);

    if(!film && allMovies.length) {
      film = allMovies[0] // Using first film if there is no featured film
    }

    if (vimeoQuery) {
      let vimeoFilmQuery = getMovieByQuery(vimeoQuery)[0] || {};
      if (
        vimeoFilmQuery &&
        vimeoFilmQuery.hasOwnProperty("movieNightFilmCategory") &&
        vimeoFilmQuery.nowShowing
      ) {
        film = vimeoFilmQuery;
        queryCategory = film.movieNightFilmCategory.name;
        movies = moviesByScreen.parsedFilms;
      }
    }

    const streamState = film ? "streaming" : "pre";

    this.state = {
      film,
      filmsByCategory,
      allMovies,
      movies,
      streamState,
      selectedCategory: queryCategory,
      curtains: "closed",
      schedule: false,
      renderVideo: false,
      thumbnailUrl: null,
      autoPlay: false,
      movieCategories,
      actualVideo: {},
      windowLoaded: false,
      categoryInfo
    };

    this.toggleSchedule = this.toggleSchedule.bind(this);
    this.streamFilm = this.streamFilm.bind(this);
    this.currentFilmHasEnded = this.currentFilmHasEnded.bind(this);
    this.viewFilm = this.viewFilm.bind(this);
    this.canViewFilm = this.canViewFilm.bind(this);
    this.checkEntryEndDate = this.checkEntryEndDate.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.getThumbnailUrl = this.getThumbnailUrl.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.scrollDiv = React.createRef();
    this.rendered = this.rendered.bind(this);

    this.autoPlay = false;
  }

  componentWillMount() {
    resetMetadata();
  }

  componentDidMount() {
    try {
      const { film } = this.state;

      this.emit = setTimeout(() => {
        if (film && !film.nowShowing) return;
        this.streamFilm(film, false);
      }, 1000);

      if (film && film.isLiveStreaming) {
        this.openPipe = setInterval(() => {
          getMovie(film._id).then(this.checkEntryEndDate).catch(console.warn);
        }, 1000 * 60); // 1 min
      }
    } catch (error) {
      console.error("Error in componentDidMount:", error);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.emit);
    clearTimeout(this.filmEnded);
    clearTimeout(this.bindRenderVideo);

    clearInterval(this.openPipe);
  }

  componentWillUpdate(nextProps, nextState) {
    const { curtains } = this.state;
    if (curtains !== nextState.curtains) {
      if (nextState.curtains === "closed") {
        this.bindRenderVideo = setTimeout(() => {
          this.setState({ renderVideo: false });
        }, 1100); //after the closing animation
      } else {
        this.setState({ renderVideo: true });
      }
    }
  }

  streamFilm(film, autoPlay = true) {
    const now = moment();
    if (film && film.isLiveStreaming && film.endDate.isBefore(now)) {
      return;
    }
    this.setState({ film, renderVideo: false }, () => {
      updateVideoStatus(film ? film.videoID : null, VIDEO_PAUSED);
      // Download vimeo thumbnail only for free/anon users since for active subscripbers the streamer is shown
      if (this.canViewFilm()) this.getThumbnailUrl(film);
      this.setState({
        autoPlay,
        actualVideo: {
          videoID: film.videoID,
          category: this.state.selectedCategory,
        },
        renderVideo: true,
        streamState: "streaming",
        curtains: "opened",
      });
    });
  }
  canViewFilm(film) {
    return requiredSubscription() && film && film.requiresSubscription
      ? false
      : true;
  }
  viewFilm(film) {
    if (!_.has(film, "film.fields.filmId")) return;
    const id = film.film.fields.filmId;
    const { router } = this.context;
    router.push(router.createLocation(`/film?id=${id}`));
  }
  currentFilmHasEnded() {
    const film = { ...this.state.film };
    film.isAfterStartDate = true;
    this.setState({ film, streamState: "post" });
  }
  onPlay() {
    Audio.pause();
  }
  toggleSchedule() {
    this.setState({ schedule: !this.state.schedule });
  }
  checkEntryEndDate(entry) {
    const film = entry;

    if (film.endDate.isSame(this.state.film.endDate) === true) return;

    this.setState({ film }, () => updateMovie(entry));
  }
  getThumbnailUrl(film) {
    if (film && film.videoID) {
      getThumbnail(film.videoID).then((result) => {
        this.setState({
          thumbnailUrl: result,
        });
      });
    }
  }
  scrollTop() {
    this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  }
  rendered() {
    try {
      this.setState({ windowLoaded: true });
    } catch (error) {
      console.error("Error during rendering:", error);
    }
  }
  renderTittle(queryCategory) {
    const { movieCategories } = this.state;
    let category = movieCategories.filter(
      (category) => category.name == queryCategory
    )[0];
    let categoryTitle = category && category.title;
    let description = category && category.description;
    return (
      <div>
        <h1 className="screenTitle">{categoryTitle}</h1>
        <p className="descriptionTitle">{description}</p>
      </div>
    );
  }
  changeCategory(category) {
    const { movieCategoryMap } = this.props;
    let film;
    if (movieCategoryMap[category] && movieCategoryMap[category].title) {
      const parsedTitle = movieCategoryMap[category].title
        .toLowerCase()
        .replace(/\s/g, "-");
      const categoryUrl = `/movie-night/${parsedTitle}`;
      window.history.replaceState({}, "/movie-night", categoryUrl);
      const filmsByCategory = store.getState().movieNightFilm.byCategoryName || {};
      const allMovies = filmsByCategory[category] || [ ];
      film = allMovies.find(film => film.isFeatured);
    }

    this.setState(
      {
        selectedCategory: category,
        movies: getMoviesByScreenCategory(category).parsedFilms,
      },
      () => {
        if (
          this.state.movies.nowShowing[0].films[0] && // Check if the next category has videos
          this.props.actualVideo && //Valid Video
          this.props.actualVideo.status !== VIDEO_PLAYING && //The video should be paused
          this.state.selectedCategory !== this.state.actualVideo.category //The next category should be different to the current video category
        ) {
          this.streamFilm(
            film || this.state.movies.nowShowing[0].films[0], 
            false
          );
        }
      }
    );
  }
  renderCategoriesButtons() {
    const items = [];
    let { movieCategories, selectedCategory } = this.state;
    for (let i = 0; i < movieCategories.length; i++) {
      let categoryName = movieCategories[i].name;
      let buttonName = categoryName.split(/(?=[A-Z0-9])/).join(" ");
      items.push(
        <div
          key={`screen-${i}`}
          className={`button category-button ${
            selectedCategory === categoryName ? "active" : ""
          }`}
          onClick={this.changeCategory.bind(this, categoryName)}
        >
          {buttonName}
        </div>
      );
    }

    items.push(
      <MovietoneLogo />
    );

    return <div className="categories-header">{items}</div>;
  }
  go(path) {
    let { router } = this.context;
    router.push(router.createLocation(path));
  }
  render() {
    try {
      const {
        curtains,
        streamState,
        schedule,
        renderVideo,
        film,
        movies,
        selectedCategory,
        windowLoaded,
        categoryInfo
      } = this.state || {};  // Ensure this.state is not null

      const image = categoryInfo ? categoryInfo.image : null;
      const curtainArtLink = image ? `url(${image})` : `url(${theaterPhoto})`;

      // Check for truly critical null values
      if (!film || !movies) {
        console.warn("Film or movies data is missing, but this may be intentional.");
      }

      const splash = !schedule && streamState === "pre";
      const streaming = !schedule && streamState === "streaming";
      const streamingPost = !schedule && streamState === "post";

      const opened = curtains === "opened",
        closed = curtains === "closed";

      return (
        <div className="movie-night">
          <div className="content-wrapper" ref={this.scrollDiv}>
            <div className="video-content">
              <div className="video">
                {renderVideo &&
                  (film && film.isLiveStreaming ? (
                    <VideoJS
                      film={film}
                      onPlay={this.onPlay}
                      canViewFilm={this.canViewFilm}
                    />
                  ) : (
                    <VOD
                      film={film}
                      prePoster={
                        (film && film.imageURL) || this.state.thumbnailUrl
                      }
                      onPlay={this.onPlay}
                      currentFilmHasEnded={this.currentFilmHasEnded}
                      canViewFilm={this.canViewFilm}
                      autoPlay={this.state.autoPlay}
                    />
                  ))}
                <div className={cn("presenting", { opened, closed })} style={{backgroundImage:curtainArtLink}} />
              </div>
              <div className={cn("curtains", { opened, closed })}>
                <div className="left" />
                <div className="right" />
                <div className="top" />
              </div>
            </div>
            <div className="button explore" onClick={() => this.go("/")}>
              EXPLORE NYA
            </div>
            <img className="down-btn" src={downArrow} />
            <div className="main-content">
              <img
                className="window"
                src={windowImg}
                onLoad={this.rendered}
                onError={this.rendered}
                loading="lazy"
              />
              <div className="content">
                {splash && (
                  <Splash film={film} showSchedule={this.toggleSchedule} />
                )}
                {windowLoaded && streaming && (
                  <Streaming
                    film={film}
                    showSchedule={this.toggleSchedule}
                    watchMovie={this.streamFilm}
                    canViewFilm={this.canViewFilm}
                  />
                )}
                {streamingPost && (
                  <Streaming
                    post={true}
                    film={film}
                    showSchedule={this.toggleSchedule}
                    watchMovie={this.streamFilm}
                    canViewFilm={this.canViewFilm}
                  />
                )}
                {this.renderCategoriesButtons()}
                {this.renderTittle(selectedCategory)}
                <Schedule
                  onClose={this.toggleSchedule}
                  watchFilm={this.streamFilm}
                  canViewFilm={this.canViewFilm}
                  movies={movies}
                  scrollTop={this.scrollTop}
                  category={this.state.selectedCategory}
                  getThumbnailUrl={getThumbnail}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } catch (error) {
      console.error("Error during render in MovieNight component:", error);
      return <div>Error loading the MovieNight component: {error.message}</div>;
    }
  }
}

MovieNight.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  if (!state.movieNightFilm.movieNightFilmLoaded) {
    return {
      loaded: false,
    };
  }
  const movieCategoryMap = state.movieNightCategory.reduce((acc, category) => {
    const categoryName = category.name || "";
    const parsedName = categoryName.toLowerCase().replace(/\s/g, "");
    acc[parsedName] = category;
    return acc;
  }, {});
  return {
    loaded: true,
    actualVideo: state.videos.actualVideo,
    movieCategoryMap,
    movieNightCategory: state.movieNightCategory,
  };
};

const MovieNightWrapper = (props) => {
  useEffect(() => {
    if (!props.loaded) {
      getHearseTheaterData().then((data) => {
        movieNightParser(data);
      }).catch(error => console.error("Error loading initial data:", error));
    }
  }, []);
  if (!props.loaded)
    return (
      <div id="timeline">
        <SplashScreen loadState={100} />
      </div>
    );
  return <MovieNight {...props} />;
};

export default connect(mapStateToProps)(MovieNightWrapper);
