import React, {Component,Fragment} from 'react'
import vimeoPlayButtonImage from '../../images/movie-night/vimeo-button.webp'
import VimeoPlayer from '../components/vimeo-player';
import  parse from 'html-react-parser';

export default class VOD extends Component {
    constructor(props){
        super(props)
        this.onVimeoClick = this.onVimeoClick.bind(this);
    }
    onVimeoClick() {
        return window.subs()
    }
    render(){
        const { canViewFilm, onPlay, onPause, currentFilmHasEnded, film, prePoster, autoPlay } = this.props
        const canView = canViewFilm(film)

        const isOrastreamVideo = film.orastreamVideoId != undefined

        return (
            canView ? (
                isOrastreamVideo && film.iframe
                ? parse(film.iframe)
                : <VimeoPlayer autoPlay={autoPlay} ref={this.vimeoRef} onPlay={onPlay} onPause={onPause} focus={true} videoId={film&&film.videoID} onEnded={currentFilmHasEnded} />
            ) : (
                <Fragment>
                  <div className="vimeo-wrapper" onClick={this.onVimeoClick}>
                    <img className="vimeo-button" src={vimeoPlayButtonImage} alt="Vimeo Play Button" loading="lazy"/>
                  </div>
                  {prePoster && <img src={prePoster} alt="Pre poster" loading="lazy"/>}
                </Fragment>
            )
        )
    }
}
