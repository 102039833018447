import React from 'react'
import { Link } from 'react-router'
import movietoneLogo from "../../images/movie-night/Movietone_Logo.webp"

const MovietoneLogo = () => {
    return (
        <Link
        to={`/movie-night/movietone`}>
            <div
                key={`movietone-library`}
                className='movietone-library-category-container'
            >
                <img className='movietone-library-logo' src={movietoneLogo} loading="lazy"/>        
            </div>
        </Link>
    )
}

export default MovietoneLogo