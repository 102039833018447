import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseOnlyNumbers } from '../../util/strings';
import { debounce } from '../frame';
import EventBusPublisher from '../event-bus-publisher';


const Tab = (props) => {
  const {
    tabRef,
    data,
    showDivider = true,
    active = false,
    handleOnClick,
  } = props;
  const titles = data.columnsTitles;
  return (
    <Fragment>
      {showDivider && <span className="divider">|</span>}
      <div
        ref={tabRef}
        tabIndex={"-1"}
        className={`tab ${active ? "active" : ""}`}
        onClick={handleOnClick}
      >
        <div className="full-title">
          {titles.map(({ title, highlighted }, i) => {
            return (
              <span
                key={i}
                className={`title ${highlighted ? "highlighted" : ""}`}
              >
                {title}
              </span>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export function Tabsline(props) {
  const isFixed = props.fixed;
  const pageData = props.pageData;
  const page = props.page;
  const router = props.router;
  const tabsRef = useRef([]);
  const showMusicTabs = props.showMusicTabs || false;
  const showWorldTabs = props.showWorldTabs || false;

  useEffect(() => {
    if (tabsRef.current[page]) {
      tabsRef.current[page].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [page, tabsRef]);

  useEffect(() => {
    const addMessageListener = () => {
      window.addEventListener("message", (event) => {
        const data = event.data || {};
        const type = data.type;
        if (type === "GOTO_NYATC_FRONT_PAGE") {
          router.push("/times-contrarian/1");
        }
      });
    };
    addMessageListener();
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  const handleOnClick = (pageTitle) => {
    pageTitle = pageTitle.toLowerCase();
    const pageNumber = pageTitle.includes("page")
      ? parseOnlyNumbers(pageTitle)
      : pageTitle || 1;
    if (page !== pageNumber) {
      props.onLoadingStart();
      router.push(`/times-contrarian/${pageNumber}`);
      EventBusPublisher.changePage(pageNumber);
    }
  };

  const renderMusicTabs = (_pageData) => {
    return _pageData.map((item, index) => {
      const isActive = item.title === `page-${page}`;
      if (item.showInBoth) {
        return (
          <Tab
            tabRef={(el) => (tabsRef.current[index] = el)}
            key={"tab-" + index}
            data={item}
            active={isActive}
            showDivider={index > 0}
            handleOnClick={() => handleOnClick(item.title)}
          />
        );
      }

      if (showMusicTabs && item.category === "music") {
        return (
          <Tab
            tabRef={(el) => (tabsRef.current[index] = el)}
            key={"tab-" + index}
            data={item}
            active={isActive}
            showDivider={index > 0}
            handleOnClick={() => handleOnClick(item.title)}
          />
        );
      }
    });
  };

  const renderWorldTabs = (_pageData) => {
    return _pageData.map((item, index) => {
      const isActive = item.title === `page-${page}`;
      if (item.showInBoth) {
        return (
          <Tab
            tabRef={(el) => (tabsRef.current[index] = el)}
            key={"tab-" + index}
            data={item}
            active={isActive}
            showDivider={index > 0}
            handleOnClick={() => handleOnClick(item.title)}
          />
        );
      }
      if (showWorldTabs && item.category === "world") {
        return (
          <Tab
            tabRef={(el) => (tabsRef.current[index] = el)}
            key={"tab-" + index}
            data={item}
            active={isActive}
            showDivider={index > 0}
            handleOnClick={() => handleOnClick(item.title)}
          />
        );
      }
    });
  };

  return (
    <>
      {showMusicTabs && (
        <div
          className={`tabsline line ${isFixed ? "fixed" : ""}`}
          id="page_tabs"
        >
          {renderMusicTabs(pageData, page)}
        </div>
      )}
      {showWorldTabs && (
        <div
          className={`tabsline line ${isFixed ? "fixed" : ""}`}
          id="page_tabs"
        >
          {renderWorldTabs(pageData, page)}
        </div>
      )}
    </>
  );
}

Tabsline.contextTypes = {
 pageData: PropTypes.object,
 fixed: PropTypes.bool,
 page: PropTypes.number,
 router: PropTypes.object,
};

const mapStateToProps = (state) => {
 let pageData = state?.contrarianPage?.all || [];
 pageData = pageData.sort((a, b) => a.order - b.order);
 return {
  pageData: pageData,
 };
};

export default connect(mapStateToProps, {})(Tabsline);
